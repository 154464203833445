import React from "react";

import "./PageNotFound.css";

export const PageNotFound = () => ( <>
    <p className="seriously">
        Seriously?!
        <br/>
        What were you expecting to find here?
        <br/><br/>
        I am intrigued now. 🤔<br/> Was this a wild guess or a calculated one?
    </p>
</> );
